import { graphql } from 'gatsby'
import React from 'react'

//
import { Layout } from '../components'
import { renderComponent } from '../utils/components'
import { buildPageMetaTags } from '../utils/seo'

const Page = ({ data, pageContext, location }) => {
  const {
    marketsSlugs,
    langSlugs,
    allLangSlugs,
    allMarkets,
    metaTags,
    marketsMetaTags,
    metaImage,
    siteMetadata,
    market,
    categorySlug,
    categories,
    microCopy,
    allLogos,
    allPublishedCustomers,
    allPublishedArticles,
    integrations,
    alternativeHeader
  } = pageContext

  const { contentfulPage } = data
  const { title, locale, components, minimalHeader, minimalFooter, minimalHeaderAction } =
    contentfulPage
  const pageData = { title, metaTags, metaImage, pageType: 'page', marketsMetaTags }

  const { slugPrefix, footer } = market

  const currentUrl = langSlugs[locale]

  const pageMetaTags = buildPageMetaTags(pageData, siteMetadata)

  // preload home hero overlay
  if (Array.isArray(components)) {
    components.forEach((component) => {
      if (component?.__typename === 'ContentfulBlockHeroHome') {
        const heroHomeOverlay = component?.image?.fluid || null

        if (heroHomeOverlay?.src) {
          const urlArray = heroHomeOverlay?.src?.split('?')

          if (urlArray?.length) {
            pageMetaTags.preload.push({
              rel: 'preload',
              as: 'image',
              href: urlArray[0],
              imagesrcset: heroHomeOverlay?.srcSetWebp || heroHomeOverlay?.srcSet,
              imagesizes: heroHomeOverlay?.sizes
            })
          }
        }
      }
    })
  }

  return (
    <Layout
      metaTags={pageMetaTags}
      marketsSlugs={marketsSlugs}
      allLangSlugs={allLangSlugs}
      allMarkets={allMarkets}
      langSlugs={langSlugs}
      header={alternativeHeader || market.header}
      footerMainNavigation={footer?.mainNavigation}
      footerSocialMedia={footer?.socialMedia}
      footerLegalNavigation={footer?.legalNavigation}
      footerActions={footer?.actions}
      marketPhoneNo={market.phoneNumber}
      marketPhoneNo2={market.phoneNumber2}
      minimalHeader={minimalHeader}
      minimalFooter={minimalFooter}
      minimalHeaderAction={minimalHeaderAction}
      location={location}
    >
      {components?.map((component, idx) =>
        renderComponent({
          component,
          extra: {
            allMarkets,
            slugPrefix,
            locale,
            categories,
            currentUrl,
            categorySlug,
            microCopy,
            allLogos,
            allPublishedCustomers,
            allPublishedArticles,
            integrations
          },
          idx
        })
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($contentfulId: String!, $locale: String!) {
    contentfulPage(contentful_id: { eq: $contentfulId }, node_locale: { eq: $locale }) {
      contentfulId: contentful_id
      title
      slug
      locale: node_locale
      minimalHeader
      minimalFooter
      minimalHeaderAction {
        ...FragmentNavigationItem
      }
      components {
        ... on Node {
          ... on ContentfulBlockCta {
            ...FragmentBlockCta
          }
          ... on ContentfulBlockIntegrationsFiltering {
            ...FragmentBlockFiltering
          }
          ... on ContentfulBlockIntegrationHero {
            ...FragmentBlockIntegrationHero
          }
          ... on ContentfulBlockRequestForm {
            ...FragmentBlockRequestForm
          }
          ... on ContentfulBlock2BlocksBanner {
            ...FragmentBlock2BlocksBanner
          }
          ... on ContentfulBlockUspList {
            ...FragmentBlockUspList
          }
          ... on ContentfulBlockLogos {
            ...FragmentBlockLogos
          }
          ... on ContentfulBlockIntegrationListing {
            ...FragmentBlockIntegrationListing
          }
          ... on ContentfulBlockMatchMaker {
            ...FragmentBlockMatchMaker
          }
          ... on ContentfulBlockWatchADemo {
            ...FragmentBlockWatchADemo
          }
          ... on ContentfulBlock3CardsWithImage {
            ...FragmentBlock3CardsWithImage
          }
          ... on ContentfulBlockCustomersTop {
            ...FragmentBlockCustomersTop
          }
          ... on ContentfulBlockBenefitsListing {
            ...FragmentBlockBenefitsListing
          }
          ... on ContentfulBlockHero {
            ...FragmentBlockHero
          }
          ... on ContentfulBlockHeroHome {
            ...FragmentBlockHeroHome
          }
          ... on ContentfulBlockRelatedArticles {
            ...FragmentBlockRelatedArticles
          }
          ... on ContentfulBlockHeroHomeTwo {
            ...FragmentBlockHeroHomeTwo
          }
          ... on ContentfulBlockHeroMedia {
            ...FragmentBlockHeroMedia
          }
          ... on ContentfulBlockFeatureContact {
            ...FragmentBlockFeatureContact
          }
          ... on ContentfulBlockFeatureSimple {
            ...FragmentBlockFeatureSimple
          }
          ... on ContentfulBlockFeatureList {
            ...FragmentBlockFeatureList
          }
          ... on ContentfulBlockSpotlight {
            ...FragmentBlockSpotlight
          }
          ... on ContentfulBlockSummary {
            ...FragmentBlockSummary
          }
          ... on ContentfulBlockCrissCross {
            ...FragmentBlockCrissCross
          }
          ... on ContentfulBlockRichText {
            ...FragmentBlockRichText
          }
          ... on ContentfulBlockSectionHeader {
            ...FragmentBlockSectionHeader
          }
          ... on ContentfulBlock2ColumnsIntegrationList {
            ...FragmentBlock2ColumnsIntegrationList
          }
          ... on ContentfulBlockOffices {
            ...FragmentBlockOffices
          }
          ... on ContentfulBlockTeamMembers {
            ...FragmentBlockTeamMembers
          }
          ... on ContentfulBlockBlogTop {
            ...FragmentBlockBlogTop
          }
          ... on ContentfulBlockFaqTop {
            ...FragmentBlockFaqTop
          }
          ... on ContentfulBlockHeroDual {
            ...FragmentBlockHeroDual
          }
          ... on ContentfulHubSpotCalendar {
            ...FragmentBlockHubSpotCalendar
          }
          ... on ContentfulBlockAnimatedImage {
            ...FragmentBlockAnimatedImage
          }
          ... on ContentfulBlockPricingCalculator {
            ...FragmentBlockPricingCalculator
          }
          ... on ContentfulBlockPricingCards {
            ...FragmentBlockPricingCards
          }
        }
      }
    }
  }
`

export default Page
