/* eslint-disable react/display-name */
import React from 'react'

//
import {
  Block2ColumnsIntegrationList,
  BlockBenefitsListing,
  BlockBlogTop,
  BlockCrissCross,
  BlockCta,
  BlockCustomersTop,
  BlockFaqTop,
  BlockFeatureContact,
  BlockFeatureSimple,
  BlockFeatureList,
  BlockFilter,
  BlockHero,
  BlockHeroDual,
  BlockHeroHome,
  BlockHeroHomeTwo,
  BlockHeroMedia,
  BlockHubSpotCalendar,
  BlockIntegrationHero,
  BlockIntegrationListing,
  BlockLogos,
  BlockMatchMaker,
  BlockOffices,
  BlockPricingCards,
  BlockRelatedArticles,
  BlockRequestForm,
  BlockRichText,
  BlockSectionHeader,
  BlockSpotlight,
  BlockSummary,
  BlockTeamMembers,
  BlockTwoBlocksBanner,
  BlockUspList,
  BlockWistiaVideo,
  Block3CardsWithImage,
  BlockAnimatedImage,
  BlockPricingCalculator
} from '../components'

const storybookComponents = {
  ContentfulBlock2BlocksBanner: (props) => <BlockTwoBlocksBanner data={props} />,
  ContentfulBlock2ColumnsIntegrationList: (props) => <Block2ColumnsIntegrationList data={props} />,
  ContentfulBlockAnimatedImage: (props) => <BlockAnimatedImage data={props} />,
  ContentfulBlockBenefitsListing: (props) => <BlockBenefitsListing data={props} />,
  ContentfulBlockBlogTop: (props) => <BlockBlogTop data={props} />,
  ContentfulBlockCrissCross: (props) => <BlockCrissCross data={props} />,
  ContentfulBlockCta: (props) => <BlockCta data={props} />,
  ContentfulBlockCustomersTop: (props) => <BlockCustomersTop data={props} />,
  ContentfulBlockFaqTop: (props) => <BlockFaqTop data={props} />,
  ContentfulBlockFeatureContact: (props) => <BlockFeatureContact data={props} />,
  ContentfulBlockFeatureSimple: (props) => <BlockFeatureSimple data={props} />,
  ContentfulBlockFeatureList: (props) => <BlockFeatureList data={props} />,
  ContentfulBlockHero: (props) => <BlockHero data={props} />,
  ContentfulBlockHeroHome: (props) => <BlockHeroHome data={props} />,
  ContentfulBlockHeroHomeTwo: (props) => <BlockHeroHomeTwo data={props} />,
  ContentfulBlockHeroMedia: (props) => <BlockHeroMedia data={props} />,
  ContentfulBlockIntegrationHero: (props) => <BlockIntegrationHero data={props} />,
  ContentfulBlockIntegrationListing: (props) => <BlockIntegrationListing data={props} />,
  ContentfulBlockIntegrationsFiltering: (props) => <BlockFilter data={props} />,
  ContentfulBlockLogos: (props) => <BlockLogos data={props} />,
  ContentfulBlockMatchMaker: (props) => <BlockMatchMaker data={props} />,
  ContentfulBlock3CardsWithImage: (props) => <Block3CardsWithImage data={props} />,
  ContentfulBlockOffices: (props) => <BlockOffices data={props} />,
  ContentfulBlockPricingCards: (props) => <BlockPricingCards data={props} />,
  ContentfulBlockPricingCalculator: (props) => <BlockPricingCalculator data={props} />,
  ContentfulBlockRequestForm: (props) => <BlockRequestForm data={props} />,
  ContentfulBlockRelatedArticles: (props) => <BlockRelatedArticles data={props} />,
  ContentfulBlockRichText: (props) => <BlockRichText data={props} />,
  ContentfulBlockSectionHeader: (props) => <BlockSectionHeader data={props} />,
  ContentfulBlockSpotlight: (props) => <BlockSpotlight data={props} />,
  ContentfulBlockSummary: (props) => <BlockSummary data={props} />,
  ContentfulBlockTeamMembers: (props) => <BlockTeamMembers data={props} />,
  ContentfulBlockUspList: (props) => <BlockUspList data={props} />,
  ContentfulBlockWatchADemo: (props) => <BlockWistiaVideo data={props} />,
  ContentfulBlockHeroDual: (props) => <BlockHeroDual data={props} />,
  ContentfulHubSpotCalendar: (props) => <BlockHubSpotCalendar data={props} />
}

export const renderComponent = ({ component, extra, idx = 0 }) => {
  if (!component?.__typename || !component?.id || !storybookComponents[component.__typename]) {
    return null
  }

  const Comp = storybookComponents[component.__typename]

  return <Comp key={`comp-${idx}=${component.id}`} {...component} extra={extra} />
}
