import React from 'react'

//
import DeliverectOGImage from '../../static/images/Deliverect-OG-Image-white.jpg'
import { SiteDataContext } from '../components'

export const buildPageMetaTags = (pageData, siteMetadata) => {
  const siteData = React.useContext(SiteDataContext)

  const { settings, market } = siteData
  const { siteImage, siteDescription, siteTitle } = settings
  const { siteName } = siteMetadata
  const { metaTags, metaImage, pageType, marketsMetaTags } = pageData

  const marketMetaTags = getMetaTagsByEnabledMarket(market, marketsMetaTags)

  const title = marketMetaTags?.title || metaTags?.title || siteTitle || pageData?.title || ''
  const titlePrefix = market.pageTitlePrefix || siteName
  const description =
    marketMetaTags?.description ||
    metaTags?.description ||
    `${pageData?.title || ''} - ${siteDescription}` ||
    ''

  const image =
    metaImage?.fixed?.src ||
    metaImage?.fixed?.images?.fallback?.src ||
    siteImage ||
    DeliverectOGImage
  const canonical = metaTags?.canonical
  const alternate = metaTags?.alternate
  const noindex = marketMetaTags?.noindex || metaTags?.noindex || false
  const nofollow = marketMetaTags?.nofollow || metaTags?.nofollow || false
  const noarchive = metaTags?.noarchive || false
  const nosnippet = metaTags?.nosnippet || false
  const maxPreviewImage = metaTags?.maxPreviewImage || false
  const keywords = metaTags?.keywords || false
  const schemaOrg = metaTags?.schemaOrg || false

  return {
    siteName,
    title: `${titlePrefix} | ${title}`,
    description,
    image,
    canonical,
    noindex,
    nofollow,
    pageType,
    noarchive,
    nosnippet,
    maxPreviewImage,
    keywords,
    schemaOrg,
    alternate
  }
}

function getMetaTagsByEnabledMarket(market, marketsMetaTags) {
  if (!marketsMetaTags) {
    return
  }

  const enabledMarketTitle = market.title.toLowerCase()
  const parsedMarketsTags = JSON.parse(marketsMetaTags.internal.content)

  return {
    title: parsedMarketsTags[enabledMarketTitle]?.title,
    description: parsedMarketsTags[enabledMarketTitle]?.description,
    noindex: parsedMarketsTags[enabledMarketTitle]?.noindex,
    nofollow: parsedMarketsTags[enabledMarketTitle]?.nofollow
  }
}
